<template>
  <div>
    <p class="text-2xl">
      Gerador de Números
    </p>
    <v-card>
      <v-form autocomplete="off">
        <validation-observer ref="observer">
          <div class="px-2">
            <v-card-text class="pt-5 pb-2">
              <v-row class="pt-2">
                <v-col
                  cols="12"
                  sm="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Número Inicial"
                    rules="required|max:1000"
                    vid="startNumber"
                  >
                    <v-text-field
                      v-model="startNumber"
                      :error-messages="errors"
                      label="Número Inicial"
                      outlined
                      dense
                      :hide-details="errors.length === 0"
                    />
                  </validation-provider>
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Quantidade"
                    rules="required"
                    vid="quantity"
                  >
                    <v-text-field
                      v-model="quantity"
                      :error-messages="errors"
                      label="Quantidade"
                      type="number"
                      outlined
                      dense
                      :hide-details="errors.length === 0"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
            </v-card-text>
          </div>

          <v-divider />

          <div class="pa-3">
            <v-card-text>
              <v-btn
                color="primary"
                class="me-3 mt-5"
                @click="generate"
              >
                Gerar Números
              </v-btn>
              <v-btn
                color="secondary"
                outlined
                class="mt-5"
                @click="resetForm"
              >
                Limpar
              </v-btn>
            </v-card-text>
          </div>
        </validation-observer>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'

export default {
  setup() {
    const startNumber = ref(null)
    const quantity = ref(null)

    return {
      startNumber,
      quantity,
    }
  },

  watch: {
    message(text) {
      this.messageChat = text.replace(/(?:\r\n|\r|\n)/g, '<br>')
    },
  },

  methods: {
    resetForm() {
      this.startNumber = null
      this.quantity = null
    },

    generate() {
      if (this.$refs.observer.validate()) {
        if (this.quantity > 200000) {
          this.$notify(
            'Quantidade máxima permitida: 200000.',
            'error',
          )

          return
        }

        const endNumber = (Number(this.startNumber) + Number(this.quantity)) - 1
        const range = (min, max) => [...Array(max - min + 1).keys()].map(i => i + min)
        const list = range(Number(this.startNumber), Number(endNumber))

        let i = 0
        let n = 0

        while (i < list.length) {
          const value = list.slice(i, i += 1000000)
          const blob = new Blob([value.join('\n')], {
            type: 'text/plain',
          })
          const a = document.createElement('a')
          a.href = URL.createObjectURL(blob)
          a.download = String((new Date()).getTime()).concat('-', n += 1)
          a.click()
        }
      }
    },
  },
}
</script>
